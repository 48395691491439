.App {
  width: 100vw;
  min-height: 100vh;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-btn {
  height: 44px;
  font-size: 16px;
  text-shadow: none;
}

.ant-modal-close-x {
  width: 40px;
  height: 50px;
  line-height: 50px;
  color: #3b3b3b;
  font-size: 20px;
}

.ant-divider-dashed {
  border-color: rgba(59, 59, 59, 0.2);
  margin: 0;
}

.ant-select-item-option-content {
  -ms-flex: auto;
  flex: auto;
  overflow: auto;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.ant-form,.ant-radio-wrapper  {
  color: #3b3b3b;
}

.ant-btn-primary[disabled] {
  background: #3b3b3b;
  opacity: 0.5;
  color: #fff;
}

.ant-btn-primary[disabled]:hover {
  background: #3b3b3b;
  opacity: 0.5;
  color: #fff;
}

.ant-form-item-has-error .ant-input {
  background-color: #fff9f9 !important;
  border-color: #f3aaaa !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e6eff8;
    border-radius: 8px !important;
    height: 44px !important;
    background-color: #f9fcff !important;
    align-items: center !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  background-color: #fff9f9 !important;
  border-color: #f3aaaa !important;
}

.ant-form-item-has-error .ant-form-item-explain {
  color: #bd0017 !important;
  font-size: 12px !important;
}

.ant-select-selection-search-input {
  height: 44px !important;
}

.ant-select-item-option {
  background-color: #f9fcff !important;
  height: 40px !important;
  align-items: center !important;
}

.ant-select-item-option-active {
  background-color: #1890ff !important;
  color: #fff !important;
}

.ant-select-dropdown {
  background-color: #f9fcff !important;
  border: 1px solid #e6eff8 !important;
  border-radius: 8px !important;
}

.ant-input {
  height: 44px;
  background-color: #f9fcff;
  border: 1px solid #e6eff8;
  border-radius: 8px;
}

textarea.ant-input {
  resize: none;
  height: 120px;
  background-color: #f9fcff;
  border: 1px solid #e6eff8;
  border-radius: 8px;
}

.ant-upload-list {
  display: none !important;
}

.ant-list-item {
  padding: 0 !important;
}

.ant-form-item-has-success .ant-form-item-explain {
  display: none !important;
}

/*修改滚动条样式*/
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 4px;
}
body::-webkit-scrollbar-thumb {
  background: #1890ff;
  border-radius: 4px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}
body::-webkit-scrollbar-corner {
  background: #fff;
}

.ant-modal-content div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.ant-modal-content div::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 4px;
}
.ant-modal-content div::-webkit-scrollbar-thumb {
  background: #1890ff;
  border-radius: 4px;
}
.ant-modal-content div::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}
.ant-modal-content div::-webkit-scrollbar-corner {
  background: #fff;
}

.ant-select-dropdown div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.ant-select-dropdown div::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 4px;
}
.ant-select-dropdown div::-webkit-scrollbar-thumb {
  background: #1890ff;
  border-radius: 4px;
}
.ant-select-dropdown div::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}
.ant-select-dropdown div::-webkit-scrollbar-corner {
  background: #fff;
}
