body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 14px;
  color: #3b3b3b;
}

body,div,dl,dt,dd,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,img {
  border: 0;
}

address,caption,cite,code,dfn,th,var {
  font-style: normal;
  font-weight: normal;
}

ol,ul {
  list-style: none;
}

caption,th {
  text-align: left;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

.a-inline-block {
  display: inline-block;
}

.b-underline {
  text-decoration: underline;
}

.p-mrb-10 {
  margin-bottom: 10px;
}
